import React from "react";
import Head from "../components/Head.js";
import Layout from "../components/Layout.js";
import News from "../components/News.js";
import LeadersBlock from "../components/LeadersBlock.js";
import RostersBlock from "../components/RostersBlock.js";

const IndexPage = () => {
  return (
    <>
      <Head
        title="The49ersHub Homepage"
        description="Your favorite place for all the SF 49ers latest news, fan data, and statistics."
      />
      <Layout>
        <main>
          <div className="main-wrapper">
            <div className="primary-wrapper">
              <div className="layout-rightsidebar">
                <div className="main main-homepage">
                  <News short />
                  <div className="link-bottom">
                    <a href="/news">
                      Read more daily 49ers news{" "}
                      <i className="fa-regular fa-hand-point-right"></i>
                    </a>
                  </div>
                </div>

                <div className="right">
                  {/* + + */}
                  <LeadersBlock />

                  {/* + + */}
                  <RostersBlock />
                </div>
              </div>
            </div>
          </div>
        </main>
      </Layout>
    </>
  );
};

export default IndexPage;
